<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Fundamental Skills: Plan for Original Investigation</h2>

      <p class="mb-n3">a) What is the goal of the original investigation?</p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-1"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsShuffled"
          :key="option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        b) What method(s) will you use in the original investigation? Provide 3 to 6 bulleted
        procedural steps describing how you will use glassware, equipment, and instrumentation.
        Additionally, detail the data acquired during fundamental skills and propose the
        variables/data you will need to collect. Include a hypothesis about which recrystallization
        conditions would be most favorable?
      </p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'Chem51LBProject3FS2OIPlan',
  components: {
    ChemicalLatex,
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {
          text: 'Choose two recrystallization methods and determine which was most effective for recovering the major component of the mixture you obtained after your reaction.',
          value: 'twoRecrystMethods',
        },
        {
          text: 'Optimize the epoxidation reaction to obtain 100% product.',
          value: 'optimizeRxn',
        },
        {
          text: 'Choose one recrystallization condition that will allow you to obtain both the product and any leftover starting material from the solid you isolated after your reaction.',
          value: 'getProductAndStartingMaterial',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
